import SCSS from './HotSpot.module.scss';

const size = 5;

type Props = {
	degree: string;
	name: string;
	currentName: string;
	x: number;
	y: number;
	changeScene: (scene: string) => void;
};

const HotSpot = (props: Props) => {
	const {name, currentName, x, y, degree, changeScene} = props;
	return (
		<g transform={`translate(${x}, ${y})`}>
			{name === currentName && (
				// <g transform='rotate(180)'>
				<g transform='translate(-100 -122.3037)'>
					<radialGradient
						id='HotSpotGradient'
						cx='100.5001'
						cy='122.6457'
						r='53.6353'
						gradientUnits='userSpaceOnUse'>
						<stop offset='0' className={SCSS.HotSpotStop1} />
						<stop offset='1' className={SCSS.HotSpotStop2} />
					</radialGradient>
					<path
						style={{transform: `rotate(${degree})`}}
						className={SCSS.HotSpotRadar}
						d='M62,84.3l38,38l38-38C117.01,63.32,82.99,63.32,62,84.3z'
					/>
					{/* </g> */}
				</g>
			)}

			<circle
				className={
					name === currentName
						? `${SCSS.HotSpotDot} ${SCSS.Active}`
						: `${SCSS.HotSpotDot}`
				}
				onClick={() => changeScene(name)}
				x={-size / 2}
				y={-size / 2}
				r={size}
			/>
		</g>
	);
};

export default HotSpot;
