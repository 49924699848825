import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='82.79,0.25 278.39,0.25 278.39,43.49 263.78,43.49 263.78,250.87 168.25,250.87 
		168.25,188.27 88.83,188.27 88.83,250.87 1.04,250.87 1.04,134.31 51.64,134.31 51.64,59.14 82.72,59.14 	'
			/>
			<rect
				className={SCSS.Balcony}
				id='Balcony'
				x='88.87'
				y='188.27'
				width='79.39'
				height='62.6'
			/>
		</g>
	);
};

export default Backdrop;
