import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import IScene from '../../scenes/IScene';
import SCENES from '../../scenes';

interface IPanorama {
	clicked: string;
	scheme: string;
	scenes: Array<IScene>;
	compass: {degree: string; view: string};
	pop: boolean;
	current: IScene;
	defaultScene: IScene;
	defaultScheme: string;
	miniplan: boolean;
	currentMarkerView: {
		latitude: number;
		longitude: number;
	};
}

const initialState: IPanorama = {
	clicked: '',
	scheme: 'scheme2',
	scenes: SCENES,
	compass: {
		degree: '0deg',
		view: '0deg',
	},
	pop: false,
	current: SCENES[0],
	defaultScene: SCENES[0],
	defaultScheme: 'scheme2',
	miniplan: true,
	currentMarkerView: {
		latitude: 0,
		longitude: 0,
	},
};

interface IStringItem {
	[key: string]: string;
}

interface INumberItem {
	[key: string]: number;
}

interface INullItem {
	[key: string]: null;
}

export const fetchPanorama = createAsyncThunk(
	'panorama/fetchByName',
	async (destinationScene: string, thunkAPI) => {
		const response: IScene = (await SCENES.find(
			(p: IScene) => p.name === destinationScene
		)) as IScene;
		return response;
	}
);

export const panoramaSlice = createSlice({
	name: 'panorama',
	initialState,
	reducers: {
		updateCurrentMarkerView: {
			reducer: (state, action: PayloadAction<INumberItem>) => {
				const latitude = action.payload.lat;
				const longitude = action.payload.long;
				state.currentMarkerView = {latitude, longitude};
			},
			prepare: (lat: number, long: number) => {
				return {payload: {lat, long}};
			},
		},
		updateCompassDegree: {
			reducer: (state, action: PayloadAction<IStringItem>) => {
				state.compass.degree = action.payload.degree;
				state.compass.view = action.payload.view;
			},
			prepare: (degree, view) => {
				return {payload: {degree, view}};
			},
		},
		changeScheme: {
			reducer: (state, action: PayloadAction<IStringItem>) => {
				state.scheme = action.payload.scheme;
			},
			prepare: (scheme) => {
				return {payload: {scheme}};
			},
		},
		updateClicked: {
			reducer: (state, action: PayloadAction<IStringItem>) => {
				state.clicked = action.payload.clicked;
			},
			prepare: (clicked) => {
				return {payload: {clicked}};
			},
		},
		toggleMiniPlan: {
			reducer: (state, action: PayloadAction<INullItem>) => {
				state.miniplan = !state.miniplan;
			},
			prepare: (mini) => {
				return {payload: {mini: null}};
			},
		},
		openPOP: {
			reducer: (state, action: PayloadAction<any>) => {
				state.pop = true;
			},
			prepare: () => {
				return {payload: {}};
			},
		},
		closePOP: {
			reducer: (state, action: PayloadAction<any>) => {
				state.pop = false;
			},
			prepare: () => {
				return {payload: {}};
			},
		},
	},
	extraReducers: {
		[fetchPanorama.fulfilled.toString()]: (state, action) => {
			state.current = action.payload;
		},
	},
});

export const {
	updateClicked,
	updateCurrentMarkerView,
	updateCompassDegree,
	changeScheme,
	openPOP,
	closePOP,
	toggleMiniPlan,
} = panoramaSlice.actions;
export const selectSCENES = (state: RootState) => state.panorama.scenes;
export const selectClicked = (state: RootState) => state.panorama.clicked;
export const selectCurrent = (state: RootState) => state.panorama.current;
export const selectMiniPlan = (state: RootState) => state.panorama.miniplan;
export const selectCurrentMarkerView = (state: RootState) =>
	state.panorama.currentMarkerView;
export const selectDefaultScene = (state: RootState) =>
	state.panorama.defaultScene;
export const selectScheme = (state: RootState) => state.panorama.scheme;
export const selectDefaultScheme = (state: RootState) =>
	state.panorama.defaultScheme;
export const selectPop = (state: RootState) => state.panorama.pop;
export const selectCompass = (state: RootState) => state.panorama.compass;
export default panoramaSlice.reducer;
