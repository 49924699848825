import SCSS from './Compass.module.scss';

type Props = {
	degree: String;
};

const Compass = (props: Props) => {
	const {degree} = props;
	return (
		<div className={SCSS.CompassContainer}>
			<svg className={SCSS.SVG} version='1.1' viewBox='0 0 200 200'>
				<g>
					<path
						className={SCSS.Text}
						d='M87.31,43.22V12.57h8.09l6.37,11.23c1.82,3.23,3.64,7.05,5,10.5h0.14c-0.46-4.05-0.59-8.19-0.59-12.78v-8.96h6.37v30.65
                    h-7.28L98.86,31.4c-1.82-3.27-3.82-7.23-5.32-10.82l-0.14,0.05c0.18,4.05,0.27,8.37,0.27,13.37v9.23H87.31z'
					/>
					<g>
						<circle className={SCSS.Circle} cx='100' cy='122.3' r='65.13' />
						<radialGradient
							id='RadialGradient'
							cx='100.5001'
							cy='122.6457'
							r='53.6353'
							gradientUnits='userSpaceOnUse'>
							<stop offset='0' className={SCSS.Stop1} />
							<stop offset='1' className={SCSS.Stop2} />
						</radialGradient>
						<path
							style={{transform: `rotate(${degree})`}}
							className={SCSS.Radar}
							d='M62,84.3l38,38l38-38C117.01,63.32,82.99,63.32,62,84.3z'
						/>
						<circle className={SCSS.CenterDot} cx='100' cy='122.3' r='8' />
					</g>
				</g>
			</svg>
		</div>
	);
};

export default Compass;
