import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polygon
				points='212.16,39.36 204.99,39.36 204.99,42.84 207.17,42.84 207.17,59.08 177.26,59.08 177.26,42.84 
			179.44,42.84 179.44,39.36 173.78,39.36 173.78,68.13 177.26,68.13 177.26,64.34 207.17,64.34 207.17,65.27 210.66,65.27 
			210.66,42.84 213.64,42.84 213.64,39.36'
			/>
			<polygon
				points='239.18,39.36 239.18,42.84 257.93,42.84 257.93,114.92 241.34,114.92 241.34,117.14 229.52,117.14 
			229.52,114.92 212.93,114.92 212.93,90.84 210.66,90.84 210.66,88.22 210.21,88.22 210.21,115.31 207.61,115.31 207.61,88.22 
			207.17,88.22 207.17,139.96 218.07,139.96 218.07,136.48 210.66,136.48 210.66,118.38 260.53,118.38 260.53,136.48 254.09,136.48 
			254.09,139.96 260.53,139.96 260.53,245.02 246.08,245.02 246.08,247.49 263.78,247.49 263.78,43.49 278.39,43.49 278.39,39.36 
			278.39,39.3 278.39,34.95 274.26,34.95 274.26,39.3'
			/>
			<polygon
				points='66.24,245.04 66.24,247.49 85.45,247.49 85.45,184.89 88.83,184.89 88.83,123.9 84.2,123.9 
			82.72,123.9 82.72,127.38 85.34,127.38 85.34,182.44 83,182.44 83,245.04'
			/>
			<polygon
				points='171.63,188.38 171.63,184.89 168.25,184.89 168.25,182.44 173.78,182.44 173.78,93.68 177.26,93.68 
			177.26,188.38 177.26,214.7 187,214.7 187,245.04 197.36,245.02 197.36,247.47 174.81,247.49 174.81,247.49 171.63,247.49'
			/>
			<polyline
				points='54.57,123.9 54.57,97.32 56.31,97.32 56.31,93.84 54.57,93.84 54.54,61.98 80.79,61.98 
			80.79,93.84 78.81,93.84 78.81,97.32 86.05,97.32 86.05,3.48 274.26,3.48 274.26,7.93 278.39,7.93 278.39,0.25 82.79,0.25 
			82.79,1.65 82.79,55.48 82.79,58.72 54.54,58.72 51.31,58.72 51.31,131.07 51.31,134.31 3.02,134.31 0.25,134.31 0.25,185.76 
			0.25,185.76 0.25,189.02 1.04,189.02 1.04,259.72 17.43,259.72 17.43,250.87 17.43,250.87 17.43,245.04 9.69,245.04 9.69,214.7 
			3.49,214.7 3.48,158.77 10.6,158.77 10.6,155.29 3.49,155.29 3.48,137.57 51.08,137.57 51.08,155.29 46.62,155.29 46.62,158.77 
			54.57,158.77 54.57,127.38 57.18,127.38 57.18,123.9 54.57,123.9'
			/>
		</g>
	);
};
export default Walls;
