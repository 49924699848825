import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '180deg',
	hotspot: {
		x: 40,
		y: 175,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.021606639653838577,
		longitude: 3.136084081797604,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.9017612388306047,
				longitude: 0.8203934001938934,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.04520876925879014,
					longitude: 0.6727687994268402,
				},
			},
		},
	],
};

export default Bedroom;
