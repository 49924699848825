import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='213.64' y1='41.1' x2='239.18' y2='41.1' />
			<line x1='56.31' y1='95.58' x2='78.81' y2='95.58' />
			<line x1='208.91' y1='65.27' x2='208.91' y2='86.34' />
			<line x1='197.36' y1='245.02' x2='246.08' y2='245.02' />
			<line x1='66.24' y1='245.04' x2='17.43' y2='245.04' />
			<polyline points='127.21,184.89 127.21,182.44 168.25,182.44' />
			<polyline points='100.64,184.89 100.64,182.44 88.83,182.44' />
		</g>
	);
};

export default index;
