import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	pan: '180deg',
	hotspot: {
		x: 220,
		y: 160,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.04355139919633144,
		longitude: 3.1703289691597876,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.84371790682673,
				longitude: 5.539467943994072,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.1105151213408746,
					longitude: 6.283177174037961,
				},
			},
		},
	],
};

export default MasterBedroom;
