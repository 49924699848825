import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '180deg',
	hotspot: {
		x: 140,
		y: 32,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.06645987556642163,
		longitude: 3.1365067304020022,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4035655770807147,
				longitude: 2.457186319372802,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.08554298417005679,
					longitude: 2.4799166060164692,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.21054853679780616,
				longitude: 3.1678702319883008,
			},
			target: {
				name: 'Living',
				view: {
					latitude: 0.0417968394535464,
					longitude: 3.1349654673796303,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.12382230220444757,
				longitude: 3.5845980372086093,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.0076094446625969425,
					longitude: 3.6519274875174417,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4122222893729055,
				longitude: 1.6867751018254997,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.09842785171222701,
					longitude: 1.5670003574549238,
				},
			},
		},
	],
};

export default Kitchen;
