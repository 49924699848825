import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	pan: '180deg',
	hotspot: {
		x: 225,
		y: 75,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.09842785171222701,
		longitude: 1.5670003574549238,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7420189368312249,
				longitude: 0.06620701486932178,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.06645987556642163,
					longitude: 3.1365067304020022,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.41798681165636853,
				longitude: 4.364086015859372,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.17319346388435886,
					longitude: 4.446605845052501,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.06034320151964301,
				longitude: 4.377791220873832,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.024561347993588445,
					longitude: 4.026299644035454,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.726250272065811,
				longitude: 3.9938061789002104,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.04355139919633144,
					longitude: 3.1703289691597876,
				},
			},
		},
	],
};

export default MainBath;
