import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '180deg',
	hotspot: {
		x: 130,
		y: 110,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.09816640868450133,
		longitude: 6.281226784772748,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2229915702915113,
				longitude: 0.10943873868508339,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.18697768047094243,
					longitude: 6.267904685459899,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5515820024354632,
				longitude: 0.9218375030578205,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.10421949745636061,
					longitude: 0.21524274629100262,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6183939439982917,
				longitude: 4.863514601502053,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.07500195357994777,
					longitude: 6.026086434292908,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.17982696927118247,
				longitude: 0.9677729885517111,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.08243105743652279,
					longitude: 0.8168256485269889,
				},
			},
		},
	],
};

export default Living;
