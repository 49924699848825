import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import Close from './CloseButton';
import RoomSelection from './RoomSelection';
import SCSS from './Pops.module.scss';
import {selectPop, closePOP} from '../../../features/panorama/panoramaSlice';

const Pops = () => {
	const dispatch = useDispatch();
	const pop = useSelector(selectPop);

	const close = () => {
		dispatch(closePOP());
	};

	return pop ? (
		<div className={SCSS.Pops}>
			<RoomSelection />
			<Close close={close} />
		</div>
	) : null;
};

export default Pops;
