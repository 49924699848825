import {render, StrictMode} from '@wordpress/element';
import App from './app/App';
import {store} from './store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './sass/app.global.scss';

var applicationContainer = document.getElementById(
	'the-grand-virtual-tour-unit-05-root'
);
applicationContainer &&
	render(
		<StrictMode>
			<Provider store={store}>
				<App />
			</Provider>
		</StrictMode>,
		applicationContainer
	);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
