import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='224.08' cy='102.84' r='0.75' />
			<line x1='57.34' y1='85.71' x2='77.78' y2='85.71' />
			<line x1='131.45' y1='19.61' x2='152.45' y2='19.61' />
			<line x1='242.79' y1='47.27' x2='253.59' y2='47.27' />
			<path
				d='M251.86,54.24c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04
			c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M254.82,54.06c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='255.94' y1='54.06' x2='254.82' y2='54.06' />
			<path d='M256.13,54.25c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='256.13' y1='54.67' x2='256.13' y2='54.25' />
			<line x1='256.13' y1='55.37' x2='256.13' y2='54.95' />
			<path d='M255.94,55.56c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='254.82' y1='55.56' x2='255.94' y2='55.56' />
			<path d='M254.63,55.38c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='256.51' y1='54.95' x2='256.51' y2='54.67' />
			<line x1='255.95' y1='54.95' x2='256.51' y2='54.95' />
			<line x1='256.51' y1='54.67' x2='255.95' y2='54.67' />
			<line x1='253.59' y1='62.34' x2='253.59' y2='55.38' />
			<line x1='242.79' y1='62.34' x2='253.59' y2='62.34' />
			<line x1='242.79' y1='47.27' x2='242.79' y2='62.34' />
			<line x1='253.59' y1='54.24' x2='253.59' y2='47.27' />
			<path
				d='M249.52,73.04c0.41,0.03,0.57,0.07,0.71,0.11c0.13,0.04,0.24,0.08,0.35,0.14c0.11,0.06,0.23,0.12,0.32,0.19
			c0.1,0.07,0.18,0.13,0.25,0.2c0.08,0.08,0.15,0.16,0.22,0.26c0.08,0.12,0.17,0.26,0.24,0.4c0.08,0.16,0.14,0.32,0.19,0.47
			c0.05,0.16,0.09,0.31,0.12,0.47c0.04,0.18,0.07,0.38,0.09,0.57c0.03,0.24,0.05,0.47,0.06,0.71c0.02,0.31,0.03,0.62,0.03,0.97
			c0.01,0.43,0.01,0.91-0.01,1.41c-0.01,0.47-0.04,0.95-0.08,1.34c-0.03,0.32-0.07,0.56-0.14,0.85c-0.05,0.19-0.11,0.4-0.18,0.57
			c-0.05,0.15-0.11,0.27-0.17,0.39c-0.06,0.11-0.13,0.21-0.23,0.33c-0.1,0.12-0.23,0.25-0.36,0.35c-0.12,0.1-0.24,0.18-0.35,0.24
			c-0.09,0.06-0.18,0.11-0.28,0.15c-0.09,0.04-0.19,0.07-0.3,0.09c-0.1,0.02-0.19,0.04-0.31,0.06c-0.12,0.02-0.27,0.04-0.65,0.04'
			/>
			<path
				d='M243.42,73.04c-0.3,0-0.53,0.01-0.74,0.04c-0.1,0.01-0.2,0.02-0.3,0.04c-0.09,0.01-0.19,0.03-0.28,0.05
			c-0.1,0.02-0.19,0.03-0.29,0.05c-0.1,0.02-0.2,0.04-0.29,0.05c-0.1,0.02-0.2,0.04-0.29,0.06c-0.1,0.02-0.19,0.04-0.29,0.07
			c-0.09,0.02-0.19,0.05-0.28,0.08c-0.09,0.03-0.18,0.06-0.27,0.09c-0.09,0.03-0.17,0.07-0.25,0.11s-0.16,0.08-0.24,0.12
			c-0.16,0.09-0.31,0.18-0.45,0.28c-0.14,0.1-0.28,0.21-0.4,0.31s-0.24,0.21-0.34,0.31c-0.1,0.1-0.19,0.2-0.26,0.29
			c-0.04,0.05-0.07,0.09-0.1,0.13c-0.03,0.04-0.06,0.09-0.08,0.13c-0.03,0.05-0.05,0.09-0.08,0.15c-0.03,0.05-0.05,0.11-0.08,0.18
			c-0.03,0.07-0.06,0.14-0.08,0.21c-0.03,0.07-0.05,0.15-0.08,0.23c-0.03,0.08-0.05,0.16-0.07,0.24c-0.02,0.08-0.04,0.16-0.06,0.24
			c-0.04,0.16-0.06,0.32-0.08,0.47c-0.01,0.08-0.02,0.15-0.02,0.22s-0.01,0.14-0.01,0.22c-0.01,0.14-0.01,0.29-0.01,0.46
			c0,0.17,0,0.35,0,0.54c0,0.19,0.01,0.38,0.02,0.55c0.01,0.09,0.01,0.17,0.02,0.25c0.01,0.08,0.02,0.16,0.03,0.24
			c0.01,0.08,0.02,0.15,0.04,0.23c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.07,0.03,0.14,0.04,0.2
			s0.03,0.12,0.04,0.18c0.01,0.03,0.02,0.05,0.02,0.08c0.01,0.03,0.02,0.05,0.03,0.08c0.02,0.05,0.04,0.11,0.07,0.16
			s0.06,0.11,0.09,0.17c0.03,0.06,0.07,0.12,0.11,0.17c0.04,0.06,0.07,0.12,0.11,0.18c0.04,0.06,0.08,0.12,0.12,0.17
			c0.04,0.06,0.09,0.11,0.14,0.17s0.1,0.12,0.16,0.18c0.06,0.06,0.12,0.12,0.19,0.18c0.07,0.06,0.14,0.12,0.22,0.19
			c0.15,0.12,0.32,0.25,0.49,0.36c0.08,0.06,0.17,0.11,0.25,0.16s0.16,0.09,0.23,0.13c0.08,0.04,0.15,0.07,0.22,0.1
			c0.07,0.03,0.14,0.06,0.21,0.08s0.14,0.05,0.2,0.07s0.14,0.04,0.2,0.06s0.14,0.04,0.21,0.06s0.14,0.04,0.21,0.05
			c0.07,0.02,0.15,0.04,0.23,0.05s0.17,0.04,0.27,0.05c0.05,0.01,0.1,0.02,0.16,0.03c0.05,0.01,0.11,0.02,0.17,0.03
			c0.12,0.02,0.25,0.04,0.39,0.05c0.14,0.02,0.28,0.03,0.42,0.04c0.14,0.01,0.28,0.02,0.41,0.02'
			/>
			<line x1='243.42' y1='73.04' x2='249.52' y2='73.04' />
			<line x1='243.42' y1='83.35' x2='249.05' y2='83.35' />
			<rect x='274.26' y='7.93' width='4.13' height='27.02' />
			<line x1='100.64' y1='184.89' x2='127.21' y2='184.89' />
			<line x1='100.64' y1='188.27' x2='127.21' y2='188.27' />
			<path d='M104.42,93.79c1.2-4.07,1.2-8.39,0-12.46' />
			<circle cx='88.68' cy='68.62' r='0.19' />
			<line x1='88.02' y1='68.04' x2='89.34' y2='68.04' />
			<polyline points='90.55,66.42 90.55,57.84 101.8,57.84 101.8,75.84 90.55,75.84 90.55,67.26' />
			<path
				d='M94.36,67.12c0.16,0.38,0.6,0.57,0.98,0.41c0.38-0.16,0.57-0.6,0.41-0.98c-0.16-0.38-0.6-0.57-0.98-0.41
			c-0.18,0.08-0.33,0.22-0.41,0.41'
			/>
			<path d='M104.42,46.21c1.2-4.07,1.2-8.39,0-12.46' />
			<path
				d='M101.8,32.29c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='102.05' y1='32.29' x2='100.99' y2='32.29' />
			<line x1='102.05' y1='32.7' x2='100.99' y2='32.7' />
			<path
				d='M101.8,37.27c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='102.05' y1='37.27' x2='100.99' y2='37.27' />
			<line x1='102.05' y1='37.67' x2='100.99' y2='37.67' />
			<path
				d='M101.8,47.27c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='102.05' y1='47.67' x2='100.99' y2='47.67' />
			<line x1='102.05' y1='47.27' x2='100.99' y2='47.27' />
			<path
				d='M101.8,42.29c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6s0.38,0.31,0.6,0.2
			c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='102.05' y1='42.69' x2='100.99' y2='42.69' />
			<line x1='102.05' y1='42.29' x2='100.99' y2='42.29' />
			<line x1='99.68' y1='51.23' x2='99.68' y2='28.73' />
			<line x1='99.15' y1='51.23' x2='99.15' y2='45.91' />
			<line x1='99.15' y1='45.68' x2='99.15' y2='28.73' />
			<line x1='87.47' y1='51.23' x2='87.47' y2='45.91' />
			<line x1='87.47' y1='45.68' x2='87.47' y2='34.29' />
			<line x1='87.47' y1='34.05' x2='87.47' y2='28.73' />
			<line x1='98.58' y1='51.23' x2='98.58' y2='45.91' />
			<line x1='98.58' y1='45.68' x2='98.58' y2='34.29' />
			<line x1='98.58' y1='34.05' x2='98.58' y2='28.73' />
			<line x1='98.3' y1='51.23' x2='98.3' y2='45.91' />
			<line x1='98.3' y1='45.68' x2='98.3' y2='34.29' />
			<line x1='98.3' y1='34.05' x2='98.3' y2='28.73' />
			<line x1='88.6' y1='44.41' x2='88.6' y2='35.55' />
			<line x1='88.6' y1='32.79' x2='88.6' y2='28.73' />
			<line x1='88.6' y1='47.17' x2='88.6' y2='51.23' />
			<line x1='88.32' y1='44.42' x2='88.32' y2='35.54' />
			<line x1='88.32' y1='32.79' x2='88.32' y2='28.73' />
			<line x1='88.32' y1='47.17' x2='88.32' y2='51.23' />
			<line x1='90.03' y1='44.32' x2='90.03' y2='35.65' />
			<line x1='90.03' y1='32.69' x2='90.03' y2='28.73' />
			<line x1='90.03' y1='47.28' x2='90.03' y2='51.23' />
			<line x1='89.75' y1='44.32' x2='89.75' y2='35.65' />
			<line x1='89.75' y1='32.69' x2='89.75' y2='28.73' />
			<line x1='89.75' y1='47.28' x2='89.75' y2='51.23' />
			<line x1='91.18' y1='44.4' x2='91.18' y2='35.56' />
			<line x1='91.18' y1='32.78' x2='91.18' y2='28.73' />
			<line x1='91.18' y1='47.18' x2='91.18' y2='51.23' />
			<line x1='91.46' y1='44.41' x2='91.46' y2='35.55' />
			<line x1='91.46' y1='32.78' x2='91.46' y2='28.73' />
			<line x1='91.46' y1='47.18' x2='91.46' y2='51.23' />
			<line x1='94.35' y1='44.41' x2='94.35' y2='35.55' />
			<line x1='94.35' y1='32.79' x2='94.35' y2='28.73' />
			<line x1='94.35' y1='47.17' x2='94.35' y2='51.23' />
			<line x1='94.06' y1='44.42' x2='94.06' y2='35.54' />
			<line x1='94.06' y1='32.79' x2='94.06' y2='28.73' />
			<line x1='94.06' y1='47.17' x2='94.06' y2='51.23' />
			<line x1='95.78' y1='44.32' x2='95.78' y2='35.65' />
			<line x1='95.78' y1='32.69' x2='95.78' y2='28.73' />
			<line x1='95.78' y1='47.28' x2='95.78' y2='51.23' />
			<line x1='95.49' y1='44.32' x2='95.49' y2='35.65' />
			<line x1='95.49' y1='32.69' x2='95.49' y2='28.73' />
			<line x1='95.49' y1='47.28' x2='95.49' y2='51.23' />
			<line x1='96.92' y1='44.4' x2='96.92' y2='35.56' />
			<line x1='96.92' y1='32.78' x2='96.92' y2='28.73' />
			<line x1='96.92' y1='47.18' x2='96.92' y2='51.23' />
			<line x1='97.21' y1='44.41' x2='97.21' y2='35.55' />
			<line x1='97.21' y1='32.78' x2='97.21' y2='28.73' />
			<line x1='97.21' y1='47.18' x2='97.21' y2='51.23' />
			<line x1='92.68' y1='51.23' x2='92.68' y2='45.91' />
			<line x1='92.68' y1='45.68' x2='92.68' y2='34.29' />
			<line x1='92.68' y1='34.05' x2='92.68' y2='28.73' />
			<line x1='92.96' y1='51.23' x2='92.96' y2='45.91' />
			<line x1='92.96' y1='45.68' x2='92.96' y2='34.29' />
			<line x1='92.96' y1='34.05' x2='92.96' y2='28.73' />
			<line x1='99.68' y1='39.98' x2='86.95' y2='39.98' />
			<line x1='99.15' y1='40.41' x2='98.58' y2='40.41' />
			<line x1='98.3' y1='40.41' x2='97.21' y2='40.41' />
			<line x1='96.92' y1='40.41' x2='95.78' y2='40.41' />
			<line x1='95.49' y1='40.41' x2='94.35' y2='40.41' />
			<line x1='94.06' y1='40.41' x2='92.96' y2='40.41' />
			<line x1='92.68' y1='40.41' x2='91.46' y2='40.41' />
			<line x1='91.18' y1='40.41' x2='90.03' y2='40.41' />
			<line x1='89.75' y1='40.41' x2='88.6' y2='40.41' />
			<line x1='88.32' y1='40.41' x2='87.47' y2='40.41' />
			<line x1='99.15' y1='39.55' x2='98.58' y2='39.55' />
			<line x1='98.3' y1='39.55' x2='97.21' y2='39.55' />
			<line x1='96.92' y1='39.55' x2='95.78' y2='39.55' />
			<line x1='95.49' y1='39.55' x2='94.35' y2='39.55' />
			<line x1='94.06' y1='39.55' x2='92.96' y2='39.55' />
			<line x1='92.68' y1='39.55' x2='91.46' y2='39.55' />
			<line x1='91.18' y1='39.55' x2='90.03' y2='39.55' />
			<line x1='89.75' y1='39.55' x2='88.6' y2='39.55' />
			<line x1='88.32' y1='39.55' x2='87.47' y2='39.55' />
			<line x1='87.47' y1='50.8' x2='88.32' y2='50.8' />
			<line x1='88.6' y1='50.8' x2='89.75' y2='50.8' />
			<line x1='90.03' y1='50.8' x2='91.18' y2='50.8' />
			<line x1='91.46' y1='50.8' x2='92.68' y2='50.8' />
			<line x1='92.96' y1='50.8' x2='94.06' y2='50.8' />
			<line x1='94.35' y1='50.8' x2='95.49' y2='50.8' />
			<line x1='95.78' y1='50.8' x2='96.92' y2='50.8' />
			<line x1='97.21' y1='50.8' x2='98.3' y2='50.8' />
			<line x1='98.58' y1='50.8' x2='99.15' y2='50.8' />
			<line x1='99.15' y1='29.17' x2='98.58' y2='29.17' />
			<line x1='98.3' y1='29.17' x2='97.21' y2='29.17' />
			<line x1='96.92' y1='29.17' x2='95.78' y2='29.17' />
			<line x1='95.49' y1='29.17' x2='94.35' y2='29.17' />
			<line x1='94.06' y1='29.17' x2='92.96' y2='29.17' />
			<line x1='92.68' y1='29.17' x2='91.46' y2='29.17' />
			<line x1='91.18' y1='29.17' x2='90.03' y2='29.17' />
			<line x1='89.75' y1='29.17' x2='88.6' y2='29.17' />
			<line x1='88.32' y1='29.17' x2='87.47' y2='29.17' />
			<line x1='87.47' y1='34.29' x2='88.41' y2='34.29' />
			<line x1='91.37' y1='34.29' x2='92.68' y2='34.29' />
			<line x1='92.96' y1='34.29' x2='94.16' y2='34.29' />
			<line x1='97.11' y1='34.29' x2='98.3' y2='34.29' />
			<line x1='98.58' y1='34.29' x2='99.15' y2='34.29' />
			<line x1='87.47' y1='34.05' x2='88.41' y2='34.05' />
			<line x1='91.37' y1='34.05' x2='92.68' y2='34.05' />
			<line x1='92.96' y1='34.05' x2='94.16' y2='34.05' />
			<line x1='97.11' y1='34.05' x2='98.3' y2='34.05' />
			<line x1='98.58' y1='34.05' x2='99.15' y2='34.05' />
			<line x1='97.11' y1='45.68' x2='98.3' y2='45.68' />
			<line x1='98.58' y1='45.68' x2='99.15' y2='45.68' />
			<line x1='94.16' y1='45.68' x2='92.96' y2='45.68' />
			<line x1='92.68' y1='45.68' x2='91.37' y2='45.68' />
			<line x1='88.41' y1='45.68' x2='87.47' y2='45.68' />
			<line x1='97.11' y1='45.91' x2='98.3' y2='45.91' />
			<line x1='98.58' y1='45.91' x2='99.15' y2='45.91' />
			<line x1='94.16' y1='45.91' x2='92.96' y2='45.91' />
			<line x1='92.68' y1='45.91' x2='91.37' y2='45.91' />
			<line x1='88.41' y1='45.91' x2='87.47' y2='45.91' />
			<circle cx='89.89' cy='34.17' r='0.86' />
			<path d='M88.95,33.41c-0.36,0.44-0.36,1.08,0,1.52' />
			<path d='M90.68,33.26c-0.46-0.4-1.14-0.39-1.59,0.01' />
			<path d='M90.82,34.93c0.36-0.45,0.36-1.09,0-1.53' />
			<path d='M89.09,35.07c0.45,0.4,1.13,0.4,1.59,0.01' />
			<circle cx='89.89' cy='45.79' r='0.75' />
			<path d='M89.13,46.67c0.43,0.38,1.08,0.38,1.52,0.01' />
			<path d='M88.99,45.07c-0.34,0.42-0.34,1.02,0,1.45' />
			<path d='M90.65,44.92c-0.44-0.38-1.08-0.37-1.52,0.01' />
			<path d='M90.79,46.52c0.35-0.43,0.35-1.03,0-1.46' />
			<path d='M96.72,46.7c0.44-0.52,0.44-1.29,0-1.81' />
			<path d='M94.69,46.84c0.53,0.48,1.35,0.48,1.88,0.01' />
			<path d='M94.55,44.89c-0.43,0.52-0.43,1.28,0,1.8' />
			<path d='M96.57,44.74c-0.54-0.48-1.35-0.48-1.88,0.01' />
			<path d='M97,46.98c0.26-0.3,0.41-0.67,0.44-1.06' />
			<path d='M95.78,47.59c0.4-0.03,0.78-0.2,1.08-0.47' />
			<path d='M94.41,47.12c0.3,0.28,0.68,0.45,1.09,0.48' />
			<path d='M93.83,45.91c0.03,0.39,0.18,0.76,0.43,1.06' />
			<path d='M94.27,44.62c-0.25,0.3-0.41,0.67-0.43,1.06' />
			<path d='M95.49,43.99c-0.41,0.03-0.79,0.2-1.09,0.48' />
			<path d='M96.86,44.47c-0.3-0.27-0.68-0.44-1.08-0.47' />
			<path d='M97.44,45.68c-0.03-0.39-0.18-0.76-0.44-1.06' />
			<path d='M94.47,33.19c-0.2,0.24-0.33,0.54-0.35,0.86' />
			<path d='M95.49,32.65c-0.33,0.03-0.64,0.17-0.88,0.39' />
			<path d='M96.66,33.03c-0.24-0.22-0.55-0.35-0.88-0.39' />
			<path d='M97.16,34.05c-0.03-0.32-0.15-0.62-0.36-0.87' />
			<path d='M96.8,35.15c0.21-0.24,0.33-0.55,0.36-0.87' />
			<path d='M95.78,35.68c0.33-0.03,0.63-0.17,0.88-0.39' />
			<path d='M94.61,35.29c0.24,0.22,0.55,0.36,0.88,0.39' />
			<path d='M94.12,34.29c0.02,0.32,0.15,0.62,0.35,0.86' />
			<path d='M94.23,32.96c-0.26,0.3-0.42,0.69-0.44,1.09' />
			<path d='M95.49,32.32c-0.42,0.03-0.81,0.21-1.12,0.49' />
			<path d='M96.89,32.81c-0.31-0.28-0.7-0.45-1.11-0.49' />
			<path d='M97.48,34.05c-0.03-0.4-0.18-0.79-0.45-1.09' />
			<path d='M97.03,35.38c0.26-0.31,0.42-0.69,0.45-1.09' />
			<path d='M95.78,36.01c0.41-0.03,0.81-0.2,1.11-0.49' />
			<path d='M94.37,35.52c0.31,0.29,0.7,0.46,1.12,0.49' />
			<path d='M93.79,34.29c0.03,0.4,0.18,0.78,0.44,1.09' />
			<line x1='96.45' y1='46.45' x2='97.21' y2='47.18' />
			<line x1='94.95' y1='45' x2='94.35' y2='44.41' />
			<line x1='96.31' y1='46.6' x2='96.92' y2='47.18' />
			<line x1='94.81' y1='45.15' x2='94.06' y2='44.42' />
			<line x1='96.45' y1='45.14' x2='97.21' y2='44.41' />
			<line x1='94.95' y1='46.59' x2='94.35' y2='47.17' />
			<line x1='96.31' y1='44.99' x2='96.92' y2='44.4' />
			<line x1='94.81' y1='46.44' x2='94.06' y2='47.17' />
			<line x1='95.49' y1='44.32' x2='95.78' y2='44.32' />
			<line x1='95.49' y1='47.28' x2='95.78' y2='47.28' />
			<line x1='94.95' y1='45' x2='94.81' y2='45.15' />
			<line x1='94.95' y1='46.59' x2='94.81' y2='46.44' />
			<line x1='96.31' y1='44.99' x2='96.45' y2='45.14' />
			<line x1='96.31' y1='46.6' x2='96.45' y2='46.45' />
			<line x1='94.16' y1='45.68' x2='94.16' y2='45.91' />
			<line x1='97.11' y1='45.68' x2='97.11' y2='45.91' />
			<line x1='90.71' y1='46.45' x2='91.46' y2='47.18' />
			<line x1='89.21' y1='45' x2='88.6' y2='44.41' />
			<line x1='90.57' y1='46.6' x2='91.18' y2='47.18' />
			<line x1='89.07' y1='45.15' x2='88.32' y2='44.42' />
			<line x1='90.71' y1='45.14' x2='91.46' y2='44.41' />
			<line x1='89.21' y1='46.59' x2='88.6' y2='47.17' />
			<line x1='90.57' y1='44.99' x2='91.18' y2='44.4' />
			<line x1='89.07' y1='46.44' x2='88.32' y2='47.17' />
			<line x1='89.75' y1='44.32' x2='90.03' y2='44.32' />
			<line x1='89.75' y1='47.28' x2='90.03' y2='47.28' />
			<line x1='89.21' y1='45' x2='89.07' y2='45.15' />
			<line x1='89.21' y1='46.59' x2='89.07' y2='46.44' />
			<line x1='90.57' y1='44.99' x2='90.71' y2='45.14' />
			<line x1='90.57' y1='46.6' x2='90.71' y2='46.45' />
			<line x1='88.41' y1='45.68' x2='88.41' y2='45.91' />
			<line x1='91.37' y1='45.68' x2='91.37' y2='45.91' />
			<line x1='90.71' y1='34.82' x2='91.46' y2='35.55' />
			<line x1='89.21' y1='33.37' x2='88.6' y2='32.79' />
			<line x1='90.57' y1='34.97' x2='91.18' y2='35.56' />
			<line x1='89.07' y1='33.52' x2='88.32' y2='32.79' />
			<line x1='90.71' y1='33.51' x2='91.46' y2='32.78' />
			<line x1='89.21' y1='34.96' x2='88.6' y2='35.55' />
			<line x1='90.57' y1='33.37' x2='91.18' y2='32.78' />
			<line x1='89.07' y1='34.82' x2='88.32' y2='35.54' />
			<line x1='89.75' y1='32.69' x2='90.03' y2='32.69' />
			<line x1='89.75' y1='35.65' x2='90.03' y2='35.65' />
			<line x1='89.21' y1='33.37' x2='89.07' y2='33.52' />
			<line x1='89.21' y1='34.96' x2='89.07' y2='34.82' />
			<line x1='90.57' y1='33.37' x2='90.71' y2='33.51' />
			<line x1='90.57' y1='34.97' x2='90.71' y2='34.82' />
			<line x1='88.41' y1='34.05' x2='88.41' y2='34.29' />
			<line x1='91.37' y1='34.05' x2='91.37' y2='34.29' />
			<line x1='96.45' y1='34.82' x2='97.21' y2='35.55' />
			<line x1='94.95' y1='33.37' x2='94.35' y2='32.79' />
			<line x1='96.31' y1='34.97' x2='96.92' y2='35.56' />
			<line x1='94.81' y1='33.52' x2='94.06' y2='32.79' />
			<line x1='96.45' y1='33.51' x2='97.21' y2='32.78' />
			<line x1='94.95' y1='34.96' x2='94.35' y2='35.55' />
			<line x1='96.31' y1='33.37' x2='96.92' y2='32.78' />
			<line x1='94.81' y1='34.82' x2='94.06' y2='35.54' />
			<line x1='95.49' y1='32.69' x2='95.78' y2='32.69' />
			<line x1='95.49' y1='35.65' x2='95.78' y2='35.65' />
			<line x1='94.95' y1='33.37' x2='94.81' y2='33.52' />
			<line x1='94.95' y1='34.96' x2='94.81' y2='34.82' />
			<line x1='96.31' y1='33.37' x2='96.45' y2='33.51' />
			<line x1='96.31' y1='34.97' x2='96.45' y2='34.82' />
			<line x1='94.16' y1='34.05' x2='94.16' y2='34.29' />
			<line x1='97.11' y1='34.05' x2='97.11' y2='34.29' />
		</g>
	);
};

export default ThinLineworks;
