import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='65.49' y='247.49' width='1.5' height='3.38' />
			<rect x='66.99' y='247.49' width='17.69' height='3.38' />
			<rect x='85.45' y='216.8' width='3.38' height='29.94' />
			<rect x='85.45' y='215.3' width='3.38' height='1.5' />
			<rect x='85.45' y='189.02' width='3.38' height='26.29' />
			<rect x='85.45' y='188.27' width='3.38' height='0.75' />
			<rect x='85.45' y='184.89' width='3.38' height='3.38' />
			<rect x='88.83' y='184.89' width='0.75' height='3.38' />
			<rect x='89.58' y='184.89' width='10.31' height='3.38' />
			<rect x='99.89' y='184.89' width='0.75' height='3.38' />
			<rect x='127.21' y='184.89' width='0.75' height='3.38' />
			<rect x='127.96' y='184.89' width='17.4' height='3.38' />
			<rect x='145.36' y='184.89' width='1.5' height='3.38' />
			<rect x='168.25' y='188.27' width='3.38' height='0.75' />
			<rect x='167.5' y='184.89' width='0.75' height='3.38' />
			<rect x='168.25' y='184.89' width='3.38' height='3.38' />
			<rect x='168.25' y='189.02' width='3.38' height='26.27' />
			<rect x='168.25' y='215.28' width='3.38' height='1.5' />
			<rect x='168.25' y='216.78' width='3.38' height='29.94' />
			<polygon points='172.4,250.87 196.61,250.85 196.61,247.49 172.4,247.49' />
			<rect x='196.61' y='247.49' width='1.5' height='3.38' />
			<rect x='198.11' y='247.49' width='25.07' height='3.38' />
			<rect x='223.19' y='247.49' width='1.5' height='3.38' />
			<rect x='224.69' y='247.49' width='20.65' height='3.38' />
			<rect x='245.33' y='247.49' width='1.5' height='3.38' />
			<rect x='246.83' y='247.47' width='16.94' height='3.39' />
			<rect x='146.86' y='184.89' width='20.65' height='3.38' />
			<rect x='17.43' y='247.49' width='0.75' height='3.38' />
			<rect x='18.18' y='247.49' width='20.73' height='3.38' />
			<rect x='38.91' y='247.49' width='1.5' height='3.38' />
			<rect x='40.41' y='247.49' width='25.07' height='3.38' />
			<line x1='245.33' y1='249.18' x2='224.69' y2='249.18' />
			<line x1='223.19' y1='249.18' x2='198.11' y2='249.18' />
			<line x1='167.5' y1='186.58' x2='146.86' y2='186.58' />
			<line x1='145.36' y1='186.58' x2='127.96' y2='186.58' />
			<line x1='99.89' y1='186.58' x2='89.58' y2='186.58' />
			<line x1='65.49' y1='249.18' x2='40.41' y2='249.18' />
			<line x1='38.91' y1='249.18' x2='18.18' y2='249.18' />
			<rect x='89.65' y='250.15' width='77.66' height='1.12' />
			<rect x='235.33' y='137.01' width='18.76' height='1.48' />
			<rect x='225.95' y='138.48' width='18.76' height='1.48' />
			<rect x='10.6' y='155.82' width='18.76' height='1.48' />
			<rect x='19.98' y='157.29' width='18.76' height='1.48' />
			<rect x='212.93' y='90.84' width='45' height='24' />
			<path
				d='M236.51,112.47l17.19-2.07c1.13-0.14,1.98-1.1,1.98-2.23V97.53c0-1.14-0.85-2.1-1.98-2.23l-17.19-2.07
			c-0.71-0.09-1.44-0.09-2.15,0l-17.19,2.07c-1.13,0.14-1.98,1.1-1.98,2.23v10.63c0,1.14,0.85,2.1,1.98,2.23l17.19,2.07
			C235.07,112.55,235.79,112.55,236.51,112.47z'
			/>
			<rect x='208.18' y='86.34' width='1.48' height='25.54' />
			<rect x='213.64' y='42.84' width='1.33' height='25.51' />
			<rect x='81.4' y='127.38' width='1.33' height='25.51' />
			<rect x='177.26' y='68.13' width='25.51' height='1.33' />
			<rect x='57.34' y='65.54' width='20.44' height='22.69' />
			<polyline points='252.14,90.84 252.14,66.84 241.05,66.84 241.05,42.84' />
			<polyline points='152.45,21.48 104.05,21.48 104.05,97.31 86.05,97.32' />
			<rect x='152.45' y='3.48' width='0.75' height='18' />
			<rect x='130.7' y='3.48' width='0.75' height='18' />
			<line x1='242.16' y1='46.71' x2='254.16' y2='46.71' />
			<line x1='251.4' y1='55.19' x2='251.4' y2='54.43' />
			<path d='M252.7,54.24c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='252.51' y1='55.19' x2='252.51' y2='54.43' />
			<path d='M252.51,55.19c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='251.59' y1='54.24' x2='255.81' y2='54.24' />
			<path d='M251.59,54.24c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='251.4' y1='55.19' x2='251.4' y2='54.43' />
			<path d='M251.4,55.19c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='255.81' y1='55.38' x2='251.59' y2='55.38' />
			<path d='M255.95,54.38c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='255.95' y1='55.24' x2='255.95' y2='54.38' />
			<path d='M255.81,55.38c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='254.16' y1='62.91' x2='254.16' y2='55.38' />
			<line x1='242.16' y1='62.91' x2='254.16' y2='62.91' />
			<line x1='242.16' y1='46.71' x2='242.16' y2='62.91' />
			<line x1='254.16' y1='54.24' x2='254.16' y2='46.71' />
			<path
				d='M243.42,72.66c-0.67,0-1.06,0.07-1.48,0.14c-0.54,0.09-1.12,0.2-1.65,0.4c-0.55,0.21-1.04,0.53-1.43,0.86
			c-0.3,0.25-0.55,0.5-0.73,0.73c-0.15,0.19-0.24,0.36-0.35,0.6c-0.1,0.23-0.2,0.51-0.28,0.8c-0.08,0.31-0.14,0.62-0.17,0.91
			c-0.03,0.3-0.03,0.57-0.03,0.91c0,0.32,0,0.7,0.03,1.04c0.03,0.32,0.08,0.61,0.12,0.87c0.04,0.22,0.08,0.43,0.15,0.63
			c0.08,0.21,0.19,0.41,0.32,0.61c0.18,0.29,0.38,0.58,0.71,0.89c0.35,0.33,0.84,0.69,1.26,0.92c0.41,0.22,0.76,0.32,1.1,0.41
			c0.29,0.08,0.56,0.15,1.04,0.23c0.39,0.07,0.92,0.13,1.4,0.13'
			/>
			<line x1='243.42' y1='83.72' x2='252.14' y2='83.72' />
			<line x1='243.42' y1='72.66' x2='252.14' y2='72.66' />

			<rect
				x='66.59'
				y='88.75'
				transform='matrix(0.294 -0.9558 0.9558 0.294 -48.0927 134.8812)'
				width='1.33'
				height='22.5'
			/>

			<rect
				x='179.18'
				y='36.04'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -2.9589 50.9272)'
				width='25.51'
				height='1.33'
			/>
			<rect x='247.24' y='7.93' width='27.02' height='1.33' />
			<rect x='100.64' y='210.34' width='0.75' height='4.5' />
			<rect x='100.64' y='188.27' width='0.75' height='4.49' />
			<polygon points='85.45,246.74 85.45,247.49 84.68,247.49 84.68,250.87 88.83,250.87 88.83,246.74' />
			<line x1='88.83' y1='250.87' x2='89.65' y2='250.87' />
			<line x1='168.25' y1='250.85' x2='167.31' y2='250.85' />
			<polygon points='171.63,246.72 171.63,247.49 172.4,247.49 172.4,250.87 168.25,250.87 168.25,246.72' />
			<rect x='104.05' y='78.56' width='0.38' height='18' />
			<path d='M104.42,95.56c1.95-5.15,1.95-10.84,0-15.99' />
			<line x1='177.26' y1='50.12' x2='207.17' y2='50.12' />
			<line x1='3.48' y1='146.57' x2='50.71' y2='146.57' />
			<line x1='210.65' y1='127.37' x2='260.53' y2='127.37' />
			<rect x='87.55' y='56.34' width='15.75' height='21' />
			<path d='M88.68,66.42c-0.23,0-0.42,0.19-0.42,0.42c0,0.23,0.19,0.42,0.42,0.42' />
			<path
				d='M89.19,66.42c-0.23-0.28-0.65-0.32-0.93-0.09c-0.28,0.23-0.32,0.65-0.09,0.93c0.23,0.28,0.65,0.32,0.93,0.09
			c0.03-0.03,0.06-0.06,0.09-0.09'
			/>
			<polyline points='88.68,67.26 88.68,67.26 94.05,67.26' />
			<line x1='94.05' y1='66.42' x2='88.68' y2='66.42' />
			<polyline points='88.02,68.89 89.34,68.89 89.34,67.26' />
			<line x1='88.02' y1='68.89' x2='88.02' y2='66.84' />
			<path d='M94.05,67.26c0.23,0,0.42-0.19,0.42-0.42c0-0.23-0.19-0.42-0.42-0.42' />
			<rect x='104.05' y='30.98' width='0.38' height='18' />
			<path d='M104.42,47.98c1.95-5.15,1.95-10.84,0-15.99' />
			<line x1='102.69' y1='28.73' x2='87.18' y2='28.73' />
			<line x1='102.69' y1='51.23' x2='87.18' y2='51.23' />
			<circle cx='101.4' cy='32.49' r='0.68' />
			<circle cx='101.4' cy='37.47' r='0.68' />
			<circle cx='101.4' cy='47.47' r='0.68' />
			<circle cx='101.4' cy='42.49' r='0.68' />
			<line x1='86.95' y1='51' x2='86.95' y2='28.96' />
			<line x1='102.92' y1='28.96' x2='102.92' y2='51' />
			<path d='M102.92,28.96c0-0.13-0.1-0.23-0.23-0.23' />
			<path d='M102.69,51.23c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M86.95,51c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M87.18,28.73c-0.13,0-0.23,0.1-0.23,0.23' />
		</g>
	);
};

export default Lineworks;
