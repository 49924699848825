import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='127.56' y='46.67' width='22.5' height='35.62' />
			<rect x='121.41' y='50.23' width='6.15' height='12' />
			<line x1='122.39' y1='62.23' x2='122.39' y2='50.23' />
			<rect x='121.41' y='66.73' width='6.15' height='12' />
			<line x1='122.39' y1='66.73' x2='122.39' y2='78.73' />
			<rect x='150.06' y='50.23' width='6.15' height='12' />
			<line x1='155.24' y1='62.23' x2='155.24' y2='50.23' />
			<rect x='150.06' y='66.73' width='6.15' height='12' />
			<line x1='155.24' y1='66.73' x2='155.24' y2='78.73' />
			<rect x='116.26' y='138.42' width='22.5' height='22.5' />
			<rect x='149.78' y='117.17' width='24' height='65.27' />
			<polyline points='152.03,117.17 152.03,120.17 170.77,120.17 170.77,179.44 152.03,179.44 152.03,182.44' />
			<line x1='149.78' y1='159.47' x2='170.77' y2='159.4' />
			<line x1='149.78' y1='140.15' x2='170.77' y2='140.15' />
			<rect x='165.66' y='120.84' width='4.5' height='57.93' />
			<rect x='88.83' y='123.9' width='9' height='45' />
			<rect x='92.61' y='128.4' width='1.12' height='36' />
			<polyline points='93.73,143.4 95.42,143.4 95.42,149.4 93.73,149.4' />
			<polyline points='92.61,149.4 90.92,149.4 90.92,143.4 92.61,143.4' />
			<rect x='9.69' y='181.58' width='0.75' height='45.75' />
			<polyline points='24.29,226.95 69.69,226.95 69.69,181.95 41.63,181.95' />
			<polyline points='27.61,181.95 10.44,181.95 10.44,226.95 18.27,226.95' />
			<rect x='9.69' y='228.24' width='13.29' height='13.29' />
			<rect x='9.69' y='167.37' width='13.29' height='13.29' />
			<polyline points='18.97,223.58 11.87,223.58 11.87,205.95 22.63,205.95' />
			<polyline points='23.72,200.71 23.72,185.33 11.87,185.33 11.87,202.58 23.33,202.58' />
			<polygon points='18.19,227.33 24.15,227.33 41.77,181.58 27.69,181.58' />
			<rect x='258.48' y='173.66' width='0.75' height='45.75' />
			<polyline points='244.63,219.04 199.23,219.04 199.23,174.04 227.29,174.04' />
			<polyline points='241.31,174.04 258.48,174.04 258.48,219.04 250.65,219.04' />
			<rect x='245.94' y='220.33' width='13.29' height='13.29' />
			<rect x='245.94' y='159.46' width='13.29' height='13.29' />
			<polyline points='249.95,215.66 257.05,215.66 257.05,198.04 246.29,198.04' />
			<polyline points='245.2,192.8 245.2,177.41 257.05,177.41 257.05,194.66 245.59,194.66' />
			<polygon points='250.73,219.41 244.77,219.41 227.15,173.66 241.23,173.66' />
		</g>
	);
};

export default Furniture;
