import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M214.97,68.35c13.57-0.71,24.21-11.92,24.21-25.51' />
			<path d='M57.18,127.38c0,13.59,10.64,24.8,24.21,25.51' />
			<path d='M177.26,93.68c13.59,0,24.8-10.64,25.51-24.21' />
			<path d='M78.21,102.67c0.43-1.75,0.64-3.54,0.64-5.34' />
			<path d='M204.99,39.36c0-1.79-0.19-3.57-0.56-5.32' />
			<path d='M247.24,9.26c0.74,14.39,12.61,25.68,27.02,25.69' />
			<rect x='100.64' y='192.76' width='0.75' height='17.59' />
			<path d='M101.38,214.83c14.38-0.4,25.84-12.18,25.84-26.56' />
		</g>
	);
};

export default DoorSwings;
