import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='130.7' y1='12.48' x2='129.2' y2='12.48' />
			<line x1='127.15' y1='12.48' x2='97.57' y2='12.48' />
			<polyline points='96.55,12.48 95.05,12.48 95.05,13.98		' />
			<line x1='95.05' y1='16.13' x2='95.06' y2='25.78' />
			<line x1='95.06' y1='26.86' x2='95.06' y2='28.36' />
			<line x1='104.05' y1='78.56' x2='102.55' y2='78.56' />
			<line x1='100.17' y1='78.56' x2='89.49' y2='78.56' />
			<polyline points='88.3,78.56 86.8,78.56 86.8,80.06		' />
			<line x1='86.8' y1='81.83' x2='86.8' y2='94.18' />
			<polyline points='86.8,95.06 86.8,96.56 88.3,96.56		' />
			<line x1='90.67' y1='96.56' x2='101.36' y2='96.56' />
			<line x1='102.55' y1='96.56' x2='104.05' y2='96.56' />
			<line x1='86.05' y1='51.61' x2='87.55' y2='51.61' />
			<line x1='89.3' y1='51.61' x2='97.17' y2='51.61' />
			<polyline points='98.05,51.61 99.55,51.61 99.55,50.11		' />
			<line x1='99.55' y1='48.26' x2='99.55' y2='30.78' />
			<polyline points='99.55,29.86 99.55,28.36 98.05,28.36		' />
			<line x1='96.3' y1='28.36' x2='88.42' y2='28.36' />
			<line x1='87.55' y1='28.36' x2='86.05' y2='28.36' />
			<line x1='95.07' y1='51.61' x2='95.07' y2='53.11' />
			<line x1='95.07' y1='55.14' x2='95.1' y2='94.8' />
			<line x1='95.1' y1='95.82' x2='95.1' y2='97.32' />
			<line x1='177.26' y1='51.62' x2='178.76' y2='51.62' />
			<line x1='180.75' y1='51.62' x2='204.68' y2='51.62' />
			<line x1='205.67' y1='51.62' x2='207.17' y2='51.62' />
			<line x1='3.48' y1='145.07' x2='4.98' y2='145.07' />
			<line x1='7.09' y1='145.07' x2='48.16' y2='145.07' />
			<line x1='49.21' y1='145.07' x2='50.71' y2='145.07' />
			<line x1='210.65' y1='125.87' x2='212.15' y2='125.87' />
			<line x1='214.15' y1='125.87' x2='258.03' y2='125.87' />
			<line x1='259.03' y1='125.87' x2='260.53' y2='125.87' />
		</g>
	);
};

export default Dashed;
